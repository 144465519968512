import AlphabeticalOrderModule from './alphabeticalOrder/alphabeticalOrder.module';
import Accordion from '@mozaic-ds/freemarker/js/modules/_accordion';
import { initShowmore } from '../common/common.utils';
const ALPHABETICAL_ORDER_MODULE_SELECTOR = 'js-children-categories-alphabetical-order';
const CHILDREN_CATEGORIES_LIST_SELECTOR = 'js-childrencategories-list';
const COMPONENT_SELECTOR = 'js-childrencategories-list';
const SHOW_MORE_TRIGGER_SELECTOR = 'js-showmore-trigger';
class ChildrenCategories extends lm.Composant {
  constructor(id) {
    super(id);
  }
  init() {
    const alphabeticalOrderElement = document.querySelector(`.${ALPHABETICAL_ORDER_MODULE_SELECTOR}`);
    if (alphabeticalOrderElement) {
      const alphabeticalOrderModule = new AlphabeticalOrderModule();
      alphabeticalOrderModule.init();
    }
  }
}
lm.DOMReady(function () {
  const childrenCategories = new ChildrenCategories('childrencategories');
  childrenCategories.init();
  const childrenCategoriesListAccordion = document.querySelector(`.${CHILDREN_CATEGORIES_LIST_SELECTOR} .js-accordion`);
  if (childrenCategoriesListAccordion) {
    try {
      new Accordion(childrenCategoriesListAccordion);
    } catch (error) {
      throw new Error(`Error : ${error}`);
    }
  }
  initShowmore(COMPONENT_SELECTOR, SHOW_MORE_TRIGGER_SELECTOR);
});