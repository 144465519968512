import { AlphabeticalOrderEnums } from './constants.enum';
class LettersMenu {
  init() {
    this.menuElement = document.querySelector(`.${AlphabeticalOrderEnums.MENU_SELECTOR}`);
    this.topLimitElement = document.querySelector(`.${AlphabeticalOrderEnums.TOP_OBSERVER_ELEMENT}`);
    this.bottomLimitElement = document.querySelector(`.${AlphabeticalOrderEnums.BOTTOM_OBSERVER_ELEMENT}`);
    if (!this.menuElement) throw ' the Alphabetical menu  is undefined';
    if (!this.topLimitElement) throw ' the top Limit Element for sticky menu is undefined';
    if (!this.bottomLimitElement) throw ' the bottom Limit Element for sticky menu is undefined';
    const lettersElements = this.menuElement.querySelectorAll(`.${AlphabeticalOrderEnums.LETTER_LINKS_SELECTOR}`);
    this.initObserverStickyLettersMenu();
    this.initLettersMenuEvent(lettersElements);
  }
  initLettersMenuEvent(lettersElements) {
    let activeLink;
    if (lettersElements.length > 0) {
      for (let i = 0; i < lettersElements.length; i++) {
        lettersElements[i].addEventListener('click', evt => {
          const target = evt.target;
          if (activeLink) activeLink.classList.remove(AlphabeticalOrderEnums.ACTIVE_CLASS_LINK);
          target.classList.add(AlphabeticalOrderEnums.ACTIVE_CLASS_LINK);
          activeLink = target;
        });
      }
    }
  }
  initObserverStickyLettersMenu() {
    if (this.topLimitElement && this.bottomLimitElement) {
      window.addEventListener('load', () => {
        this.observerTopLettersMenu = new IntersectionObserver(this.setStickyMenuLetters.bind(this), {
          threshold: 0.5
        });
        this.observerTopLettersMenu.observe(this.topLimitElement);
        this.observerFooterLettersMenu = new IntersectionObserver(this.setStickyMenuLetters.bind(this));
      });
    }
  }
  setStickyMenuLetters(entries) {
    if (!entries[0].isIntersecting) {
      this.menuElement.classList.add(AlphabeticalOrderEnums.CLASS_STICKY_FIXED);
      this.observerFooterLettersMenu.observe(this.bottomLimitElement);
    } else {
      this.menuElement.classList.remove(AlphabeticalOrderEnums.CLASS_STICKY_FIXED);
    }
  }
}
export default LettersMenu;